#loyalty__content {
  .e-list_not_signed-left__inner {
    @media #{$medium-up} {
      background: none;
    }
  }
  .e-list_not_signed-left {
    @media #{$medium-up} {
      background: none;
    }
    @media #{$medium-down} {
      background: none;
      padding: 60px 0 0 20px;
      .title_main {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }
  .e-list_not_signed-right .footnote a {
    text-decoration: underline;
  }

  // Reinstate the program title but with our styling tweaks
  .e-list_not_signed {
    .title_super {
      display: block;
      visibility: visible;
    }
    .title_main {
      display: block;
      visibility: visible;
      margin-top: 0px;
      margin-left: 0px;
    }
    .e-list_not_signed-right {
      @media #{$medium-down} {
        .btn_join {
          padding: 0 5px !important;
          font-size: 9px;
          text-align: left;
        }
      }
      .btn_join {
        padding: 0 20px;
      }
    }
    @media #{$small-only} {
      .title_super {
        font-family: '#{$source-sans-3}';
      }
      .title_main {
        font-family: '#{$source-sans-3}';
        font-size: 32pt;
      }
      .sub_title {
        font-size: 30px;
      }
    }
  }
}

#my_points {
  .form-item.loyalty_group {
    .loyalty_option {
      display: block;
      margin-bottom: 0.5em;
    }
  }
  #loyalty_join_gr {
    .form-item {
      &.phone_1_container {
        @media #{$medium-up} {
          width: 100%;
        }
        .phone_1_elements {
          width: 50%;
        }
      }
    }
  }
}

.account-page {
  .sidebar-page__content {
    #sms_verification {
      fieldset {
        p:first-of-type {
          margin-top: 0px;
          margin-left: 0.4em;
        }
        .form-item {
          .required_mark {
            margin: 0 0.4em;
          }
        }
      }
      #continue-btn {
        margin-left: 1.9em;
      }
    }
    #loyalty_greece {
      .error_messages {
        padding-left: 20px;
        padding-bottom: 10px;
      }
    }
  }
}

.section-loyalty-programs {
  #colorbox.colorbox__quickshop {
    .quickshop {
      &__description {
        background-color: white;
        padding: 2em;
        @include min-height(725px);
        &-section {
          .quickshop__price-size-select-container {
            display: none;
          }
        }
      }
      &__header {
        width: 400px;
        max-width: 400px;
      }
      &__reviews {
        display: none;
      }
      &__price-text {
        display: none;
      }
      &__button-container {
        display: none !important;
      }
      &__personal {
        .quickshop__wishlist {
          display: none;
        }
      }
    }
  }
  .loyalty-tier {
    &:nth-of-type(1) {
      background-color: #0f1b2a;
      div {
        .hero-tout-fullwidth-wrapper {
          margin-bottom: 50px;
        }
      }
    }
    .tier_header {
      .amount,
      .unit {
        font-weight: bold;
        font-family: $optimaregular;
        color: #a5976e;
      }
      .amount {
        font-size: 3em;
      }
      &.points-title {
        /* Tier header side lines */
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        p {
          position: relative;
          display: inline-block;
          margin: 0.6em 0;
          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 9999px;
            height: 2px;
            background: #ae9e71;
          }
          &:before {
            right: 100%;
            margin-right: 15px;
          }
          &:after {
            left: 100%;
            margin-left: 15px;
          }
        }
      }
    }
    .aerin-landing-row {
      margin-bottom: 0px;
      &__slot--2-weighted-even {
        width: 44%;
      }
      &__slot--2-weighted-left:first-child,
      &__slot--2-weighted-even:first-child {
        margin: 0 2% 0 4%;
      }
      &__slot--2-weighted-left:last-child,
      &__slot--2-weighted-even:last-child {
        margin: 0 4% 0 2%;
      }
      .basic-3-col-table {
        td.basic-3-col-table-column-title {
          color: #ae9e71;
          font-weight: bold;
          text-align: left;
          font-size: 1.8em;
          font-family: $optimaregular;
        }
      }
    }
    .pc-carousel-formatter {
      margin-bottom: 0px;
      .slick-slider {
        margin-bottom: 0px;
      }
    }
    .discover-more {
      padding-bottom: 0px;
      &__products {
        width: 100%;
        .discover_more__product {
          padding-bottom: 1em;
          &-image-container {
            width: 100%;
            height: auto;
          }
          &-image {
            width: 100%;
            height: auto;
            left: 0px;
            position: relative;
          }
          &-header {
            margin-top: 0.4em;
          }
          &-price {
            display: none;
          }
          &-quickshop {
            margin-top: 0.8em;
          }
          &:only-child {
            width: 46%;
            margin: 0 27%;
          }
        }
      }
    }
    .or-title {
      margin-top: 2em;
    }
    .accordian-page__accordian {
      & > .content-block-title {
        color: #a5976e;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0.5em;
        font-family: $optimaregular;
      }
    }
    .bold {
      font-weight: bold;
    }
    .discover-more {
      .discover_more__product {
        &-header {
          font-size: 1.4em;
          letter-spacing: -0.75px;
        }
        &-sub-header {
          @media #{$medium-up} {
            line-height: 2.5ex;
            height: 5ex;
            overflow: hidden;
          }
        }
      }
    }
    .tier1-content,
    .tier4-content {
      div.basic-textarea-v1 {
        p {
          color: #a5976e;
          font-size: 1.5em;
          margin: 0.5em 0 1em;
          font-family: $optimaregular;
        }
        & + div {
          width: 70%;
          margin-left: 15%;
          margin-bottom: 2em;
          .discover-more {
            padding-bottom: 0px;
            .discover_more__product {
              padding-bottom: 0px;
            }
          }
        }
      }
      .loyalty_tier4_main_section {
        width: 70%;
        margin-left: 15%;
        margin-bottom: 2em;
        .discover-more {
          padding-bottom: 0px;
          .discover_more__product {
            padding-bottom: 0px;
          }
        }
      }
      .discover-more {
        /* two items */
        li:first-child:nth-last-child(2),
        li:first-child:nth-last-child(2) ~ li {
          width: 32%;
          margin: 0 9%;
        }
        /* three items */
        li:first-child:nth-last-child(3),
        li:first-child:nth-last-child(3) ~ li {
          width: 32%;
          margin: 0 0.65%;
        }
      }
      .discover_more__product {
        /* 1item */
        &:only-child {
          width: 32%;
          margin: 0 34%;
        }
      }
    }
    .tier2a-content,
    .tier3a-content {
      margin-bottom: 2em;
      .aerin-landing-row__slot--3-weighted-even {
        &:first-child {
          width: 40%;
          margin: 0 0 0 4%;
        }
        &:nth-child(2) {
          width: 10%;
          margin: 0 1%;
          padding: 15% 0;
          .basic-textarea-v1 p {
            color: #a5976e;
            text-align: center;
            font-size: 2em;
          }
        }
        &:last-child {
          width: 40%;
          margin: 0 4% 0 0;
        }
        /* 2 items */
        .discover_more__product {
          width: 46%;
          &:first-child {
            margin: 0 4% 0 0;
          }
          &:last-child {
            margin: 0 0 0 4%;
          }
          &:only-child {
            width: 46%;
            margin: 0 27%;
          }
        }
      }
    }
    .tier5-content,
    .tier6-content {
      .discover-more {
        h3.discover-more__header {
          color: #a5976e;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0.5em;
          font-size: 30px;
          margin-bottom: 0.5em;
          padding: 0px;
        }
      }
    }
    .tier5-content {
      .discover-more {
        &__products {
          width: 60%;
          margin-left: 20%;
          /* four items */
          .discover_more__product {
            width: 24%;
            &:nth-child(2) {
              margin: 0 0.5% 0 1%;
            }
            &:nth-child(3) {
              margin: 0 1% 0 0.5%;
            }
          }
        }
      }
    }
    .tier6-content {
      .discover-more__products {
        width: 60%;
        margin-left: 20%;
        .aerin-landing-row__slot--2-weighted-left {
          &:first-child {
            width: 50%;
          }
          &:last-child {
            width: 38%;
            td.col1 {
              text-align: left;
            }
          }
        }
        /* three items */
        li:first-child:nth-last-child(3),
        li:first-child:nth-last-child(3) ~ li {
          width: 32%;
          margin: 0 0.65%;
        }
        /* four items */
        .discover_more__product {
          width: 24%;
          &:nth-child(2) {
            margin: 0 0.5% 0 1%;
          }
          &:nth-child(3) {
            margin: 0 1% 0 0.5%;
          }
          @media #{$medium-up} {
            &-header {
              line-height: 2.5ex;
              height: 5ex;
              overflow: hidden;
            }
          }
        }
      }
    }
    #bottom_info {
      text-align: center;
      margin: 0 1em 1em;
      margin-top: 5%;
      .main_line {
        color: #a5976e;
        font-size: 1.2em;
        margin-bottom: 0.8em;
      }
      p {
        line-height: 1.2em;
        margin: 0;
      }
    }
  }
}

footer {
  .footer-legal {
    @media #{$medium-up} {
      padding: 0 1.5%;
      height: 80px;
      .menu li {
        padding-right: 1em;
      }
    }
    @media #{$large-up} {
      .menu li {
        padding-right: 2em;
      }
    }
    @media #{$xxlarge-up} {
      height: 40px;
    }
  }
}

//
// scss/components/header/_page_utilities.scss
//

//.page-utilities-elc-nodeblock,
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.page-utilities__account-button {
  cursor: pointer;
  padding: 0 20px 0 10px;
  @media #{$small-only} {
    padding: 0;
  }
}

.page-utilities__account-text {
  .elc-user-state-logged-in & {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    @media #{$small-only} {
      letter-spacing: 0;
      font-size: 11px;
      padding-top: 2px;
      .js-is_signed_in {
        display: none;
      }
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    .page-utilities__loyalty & {
      position: static;
      min-width: 0;
    }
  }
}

// loyalty
.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    right: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    #{$alt} & {
      color: $color-white;
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        .js-loyalty-points-value {
          font-weight: bold;
        }
        #{$alt} & {
          color: $color-white;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    right: 100px;
  }
  @media #{$large-only} {
    right: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-left: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
  .sign-out-link {
    margin-left: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      @media #{$medium-up} {
        color: $color-white;
      }
    }
  }
}

.loyalty_mrkt_ldng {
  &::before {
    background: url('/media/export/cms_2.0/loyalty_ldg_bg_v2.jpg') no-repeat 0 0;
  }
  hr {
    width: 75%;
    border: 0;
    height: 1.5px;
    background: $color-navy;
    margin-bottom: 50px;
  }
}

.loyalty_header {
  max-width: $medium-max;
  margin: 0 auto 100px;
  position: relative;
  p {
    margin: 20px;
  }
}

.loyalty_multi_image_tout_right {
  img {
    width: 320px;
  }
}

.tier_header {
  .div_content_body {
    p {
      font-size: 40px;
      line-height: normal;
      font-family: $source-sans-3;
      font-weight: 400;
      margin: 0 auto;
    }
  }
}

.pc-carousel-formatter {
  padding-bottom: 0;
}

.device-pc {
  .discover_more {
    padding-bottom: 0;
    &__product {
      margin: 0 20px;
    }
    &__product-price {
      display: none;
    }
  }
  .discover-more {
    padding-bottom: 0;
    &__products {
      width: max-content;
      margin: 0 auto;
    }
  }
}

.cta-btn {
  display: block;
  background: $color-navy;
  font-size: 12px;
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: $color-white;
  max-width: 200px;
  margin: 1.5rem auto 0;
  padding: 1rem;
}
