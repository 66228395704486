/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: 'Ναί (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: 'Οχι (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              font-size: 11px;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='ικια'] {
          &::before {
            content: 'Φίλτρο:';
            font-size: 11px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 56%;
          margin-top: 20px;
          .pr-multiselect-button {
            span {
              font-size: 9px;
            }
          }
        }
        .pr-rd-review-header-sorts {
          padding-left: 13.8%;
          .pr-rd-sort-group {
            &::before {
              content: 'Είδος: ';
              font-size: 10px;
              top: 7px;
            }
            .pr-rd-sort {
              font-size: 9px;
              background-size: 19px 43px;
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 80px;
            .pr-search-icon {
              &::after {
                content: 'ΑΝΑΖΗΤΗΣΗ';
                font-size: 11px;
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 84px;
          }
        }
        .pr-rd-main-header-search {
          width: 44%;
        }
        .pr-multiselect[data-reactid-powerreviews$='lauderγια'] {
          display: none;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' από 5';
            }
          }
        }
      }
    }
  }
}
