.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      margin: 0 auto;
      padding-top: 0;
      .checkout_container {
        .checkout__sidebar {
          #checkout-sidebar {
            .payment-panel {
              .gift_container,
              .promocode-container {
                display: none;
              }
              #review-address-info {
                .transaction-item {
                  &.offer-code-message-section {
                    display: block;
                    .giftwrap-content {
                      display: none;
                    }
                  }
                }
              }
            }
            .order-summary-panel {
              #order-status {
                td {
                  &.tax-label {
                    color: $color-light-gray;
                    font-size: 8px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .checkout__content {
          #address-section-container {
            .shipping-panel__title {
              margin-top: 40px;
            }
            .address_form_container {
              padding-top: 5px;
            }
            .billing-address-form-container {
              .state_container {
                display: none;
              }
            }
          }
        }
      }
      .payment-panel {
        #payment-type-ncc {
          border-bottom: none;
        }
      }
    }
  }
  &.co_updates_enabled {
    &#payment {
      #review-address-info {
        .transaction-details {
          .transaction-item {
            &.gift_container {
              display: none;
            }
          }
          h3.checkout__subtitle {
            width: 100%;
          }
        }
      }
      .payment-type {
        label {
          img {
            width: auto;
          }
        }
      }
    }
  }
  &#payment.co_updates_enabled {
    .payment-panel {
      #payment-other-terms {
        padding: 0;
        label {
          @include swap_direction(padding, 15px 18px 15px 56px);
          border-top: 1px solid $color-navy;
          margin-top: 0;
          &::before {
            #{$ldirection}: 18px;
            top: 18px;
          }
          &::after {
            #{$ldirection}: 24px;
            top: 20px;
          }
        }
      }
      .installopts {
        padding-bottom: 17px;
        select {
          background-size: contain;
        }
      }
    }
  }
}
