.store-locator {
  .local-search__search-container {
    .local-search-form {
      .local-search-form__city-state-wrapper {
        .local-search-form__city {
          width: 100% !important;
        }
      }
    }
  }
}

.email_popover {
  .email_popover__content {
    .welcome-title {
      span {
        font-size: 2em !important;
      }
    }
  }
}

.results {
  .search-product__details {
    .search-product__cta {
      a.cta {
        letter-spacing: 0.1em;
      }
      .selectBox-label {
        display: block;
        letter-spacing: 0.07em;
      }
    }
  }
}

#cs-container {
  &.pc-unsubscribe,
  &.email-signup-thanks {
    width: 100%;
    max-width: 1281px;
    margin: 0 auto;
    min-height: 250px;
    input[type='text'] {
      width: 100%;
    }
  }
}

.spp-product__how-to-use {
  .spp-howtouse {
    .headline--secondary {
      font-size: 45px;
    }
  }
}

.mpp__product {
  .product_brief {
    &__button--add-to-bag {
      &.button--secondary {
        line-height: 33px;
      }
    }
  }
}

.ff-quiz__inner {
  .ff-quiz__button {
    &--next {
      background-position: 15px 0;
      width: 130px;
    }
  }
}

.typeahead-wrapper {
  .product-result {
    &__button {
      .button--dark-secondary {
        line-height: 32px;
      }
    }
  }
}

.back-to-top {
  width: 85px;
  #{$rdirection}: 10px;
}

.device-pc {
  .discover-more {
    font-style: normal;
  }
}

.spp-product__mini-bag-add-button {
  &.button {
    line-height: normal;
  }
}

.cart-confirm__shade-button {
  line-height: normal;
}

#colorbox.colorbox__quickshop {
  .quickshop {
    &__reviews {
      display: none;
    }
    &__headers {
      max-height: 100%;
    }
  }
}

.section-night-experience {
  .r4_night_routine-processed {
    #r4_mod5_cat_1 {
      padding-#{$ldirection}: 15%;
    }
    #r4_mod5_cat_3 {
      padding-#{$ldirection}: 4%;
    }
    #r4_mod5_cat_4 {
      padding-#{$ldirection}: 12%;
    }
    .r4_mod5_qs {
      display: none;
    }
  }
}

.ff-results__matches-sub-headline,
.ff-results__regimen-steps {
  display: none;
}

.customer-service {
  .sidebar-page__content li {
    min-height: 100%;
  }
}

.field-menu {
  .menu-container {
    padding-top: 0px;
  }
}
// LOYALTY
.sign-in-component--loyalty {
  .sign-in-component__confirm--join-loyalty {
    display: none;
  }
}

.signin-overlay-loyalty {
  #cboxContent {
    @media #{$medium-up} {
      padding: 0;
      background: url(/media/export/cms_2.0/loyalty/signin/bg01.jpg) no-repeat 0 -20px $color-white;
    }
  }
  @media #{$medium-up} {
    .sign-in-component--loyalty {
      padding-top: 70px;
    }
    .registration-confirmation {
      background: none;
      .sign-in-component__header {
        font-size: 30px;
        letter-spacing: -0.005em;
      }
    }
  }
}

// wrapper for perlgem
.sign-in-page--loyalty {
  @media #{$medium-up} {
    background: url(/media/export/cms_2.0/loyalty/signin/bg01.jpg) no-repeat 0 0 $color-white;
    margin-top: -82px;
    &-container {
      background: url(/media/export/cms_2.0/loyalty/signin/bg03.jpg) no-repeat bottom right;
      .sign-in-component--loyalty {
        background: none;
        width: 950px;
        margin: 0 auto;
      }
    }
  }
}

.sign-in-component--loyalty {
  padding: 0 0 40px 0;
  @media #{$medium-up} {
    @include clearfix;
    width: 100%;
    padding: 162px 0 0 0;
    height: 630px;
    background: url(/media/export/cms_2.0/loyalty/signin/bg03.jpg) no-repeat bottom right;
  }
  .sign-in-component {
    &__header {
      font-family: $optimalight;
      font-size: 40px;
      line-height: 42px;
      letter-spacing: -0.05em;
      color: $color-navy;
    }
  }
  .sign-in-component__form {
    &--registration,
    &--sign-in {
      margin: 0;
      width: 100%;
      @media #{$medium-up} {
        height: 520px; //600 - 80
        //background: url(/media/images/common/vertical_black_line.gif) repeat-y 50% 0;
      }
    }
    &-loyalty {
      display: none;
      @media #{$medium-up} {
        display: block;
        float: left;
        width: 56%;
        color: $color-navy;
        padding: 0 78px 0 92px;
        height: 520px; //600 - 80
        background: url(/media/export/cms_2.0/loyalty/signin/bg02.jpg) no-repeat bottom right;
      }
      &-header {
        font-family: $optimalight;
        font-size: 40px;
        line-height: 42px;
        letter-spacing: -0.05em;
        margin: 20px 0 0 5px;
      }
      &-text {
        font-size: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        margin-left: 6px;
        p {
          margin: 15px 0;
          line-height: normal;
        }
        a {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.15em;
          color: $color-navy;
        }
      }
    }
    &-content {
      @media #{$medium-up} {
        width: 44%;
        float: right;
        color: $color-navy;
        padding-left: 25px;
      }
      &--sign-in {
        margin-top: 88px;
      }
    }
    .form-submit {
      @media #{$medium-up} {
        width: 270px;
      }
    }
    &-loyalty-link {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15em;
      color: $color-navy;
    }
  }
}

.sign-in-component--loyalty {
  @media #{$medium-up} {
    .sign-in-component__form {
      &-content {
        padding-left: 35px;
        .sign-in-component__header {
          width: 300px;
          font-size: 40px;
          line-height: 40px;
          margin-bottom: 20px;
          position: relative;
          bottom: 6px;
        }
        .form-item {
          margin-bottom: 12px;
        }
        .sign-in-component__password-wrapper {
          margin-bottom: 26px;
        }
        .sign-in-component__registration-options {
          width: 335px;
          .sign-in-component__loyalty.text--form-help label,
          .sign-in-component__terms.text--form-help label {
            margin-bottom: 10px;
          }
        }
        .form-submit {
          margin-bottom: 15px;
        }
      }
      &-loyalty {
        height: 530px;
        padding: 8px 20px 0 81px;
        .sign-in-component__form-loyalty-header {
          margin: 13px 0 0;
          letter-spacing: -0.05em;
          word-spacing: 1px;
          line-height: 40px;
        }
        .sign-in-component__form-loyalty-text {
          p {
            line-height: 19px;
            margin: 17px 0;
            position: relative;
            right: 7px;
          }
        }
      }
    }
  }
}

.utility-nav__account {
  @media #{$medium-up} {
    left: -20px;
  }
  @media #{$xlarge-up} {
    left: -10px;
  }
}

.oculus_formatter {
  .oculus_mod1_wrapper {
    #oculus_hero_block_3 {
      font-size: 24px;
      font-family: $source-sans-3;
    }
  }
  .el_boutique_wrapper {
    .oculus_quiz_copy_body {
      font-size: 20px;
      font-family: $source-sans-3;
    }
    .oculus_serum_para1 {
      font-size: 24px;
      font-family: $source-sans-3;
    }
  }
}

h1 {
  font-family: $source-sans-3;
}

.mpp-header-anr-night-protein-elc-nodeblock {
  .multi_use_mpp_v1 {
    .regular {
      color: $color-white;
    }
  }
}

.multi-use-style-b-anr-limited-time-offer-elc-nodeblock {
  .multi_use_homepage_v1 {
    .regular {
      color: $color-white;
    }
  }
}
